import { Controller } from "stimulus";

export default class extends Controller {
  static values = { title: String, text: String, url: String };

  async share(event) {
    event.preventDefault();

    if (navigator.share) {
      navigator.share({
        title: this.titleValue,
        text: this.textValue,
        url: this.urlValue,
      }).catch(error => {
        console.error('Something went wrong sharing the content', error);
      });
    } else {
      this.doShareFallback();
    }
  }

  async doShareFallback() {
    try {
      await navigator.clipboard.writeText(this.urlValue);
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
    window.prompt("Link copied to clipboard.  Go forth and share!", this.urlValue);
  }
}
