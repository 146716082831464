import { Controller } from "stimulus";

export default class extends Controller {
  static values = { contentToCopy: String };
  originalHTML = "";

  connect() {
    this.originalHTML = this.element.innerHTML;
  }

  async copyToClipboard(event) {
    event.preventDefault();

    try {
      await navigator.clipboard.writeText(this.contentToCopyValue);

      // Update the element content to "COPIED"
      this.element.innerHTML = "<span class='text-sm text-green-500'>copied!</span>";

      // Restore the original content after 1.5 seconds
      setTimeout(() => {
        this.element.innerHTML = this.originalHTML;
      }, 1500);

    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  }
}
