// menu_toggle_controller.js
// This controller is responsible for toggling the marketing/public pages
// hamburger menu open and closed.
// It's bare bones but it works.  But we really should add some love and animations to it.
// It was written by a Centaur.
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["showOnMenuOpen", "hideOnMenuOpen"];

  toggle() {
    // Toggle visibility for the elements that should be shown when the menu is open
    this.showOnMenuOpenTargets.forEach(el => el.classList.toggle('hidden'));

    // Toggle visibility for the elements that should be hidden when the menu is open
    this.hideOnMenuOpenTargets.forEach(el => el.classList.toggle('hidden'));

    // Toggle the overflow property of the body to prevent/allow scrolling
    if (document.body.style.overflow !== "hidden") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }

}
